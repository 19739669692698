import React from 'react'
import Classes from './Pluto.css'
import {CustomView, isBrowser, isMobile, isTablet, isIPad13}  from 'react-device-detect'


const pluto = () => {
    return (
        <section class="product_section pluto_product_section">
        <h2 id="products"></h2>
        <h2>RATIONALMINDS PLUTO</h2>
        <div>
        <span>{isIPad13}</span>
        <p class="product_text">
            Experience unparalleled efficiency with Pluto, the ultimate log search solution for distributed environments, all from a single interface. Simply deploy the secure Charon jars on your nodes, and you’re ready to go. Pluto operates without storing, indexing, or exporting logs, allowing you to search logs directly on the designated server. Tailored for tier 1 and 2 support teams, it empowers IT professionals to easily access current logs sorted by date in their preferred time zones, eliminating the hassle of server logins or cumbersome command-line queries. Installation is a breeze, requiring only basic hardware and a simple Java container (Apache Tomcat® recommended) with no complex configurations—just install the agents and set user access. With intuitive design and established default parameters, Pluto is ready for immediate use with virtually no training required.
        </p>

        <p class="product_text">
            With the release of Pluto version 5.0, powerful algorithms now enable precise log file searches within specific time ranges down to the second, a game-changer for support and DevOps teams navigating extensive log collections scattered across networks.
        </p>

        <p class="product_text">
            Enjoy lightning-fast results, as Pluto leverages parallel computation to gather and compile data efficiently. Users can view results tailored to their local time zones, with the system automatically identifying and converting dates accordingly—supporting five time zones to enhance chronological analysis. Non-dated logs are conveniently categorized into a separate tab for clarity.
        </p>

        <p class="product_text">
            Pluto also incorporates a robust feedback mechanism, listing any faults, restrictions, or limitations encountered during searches. Security remains a top priority, with built-in restrictions on concurrent searches and data set sizes to ensure optimal performance on both guest and host servers.
        </p>

          <p><a href="http://coffeefromme.blogspot.com/2017/07/pluto-log-search-tool.html" target="_blank" title="blogger"  class="external_links">Click here to learn more</a></p>
          <p><a href="https://rm-pluto.herokuapp.com/pluto" target="_blank" title="demo"  class="external_links">Click here to explore live demo of Pluto. The data in demo is not real and is generated randomly. </a></p>
        </div>
        <CustomView condition={isBrowser && !isIPad13}>
          <div class="pluto_video_download_article_desktop pluto_video_desktop">
            <object height="100%" width="100%" data="https://www.youtube.com/embed/eOtRh3uceCI"/>
          </div>         
          <div class="pluto_download_div"> 
            <a href="http://rationalminds.net/download/pluto/5.0/pluto.war" data-role="none">
              <span class="pluto_download_btn">Download 5.0</span>
            </a> 
            <span class="downloadConditions">
              <a href="http://www.binpress.com/license/view/l/a33a806553ad7035dd79f7aa207fa101" target="_blank" class="external_links">Terms & Conditions</a>
            </span> 
          </div>
        </CustomView>

        <CustomView condition={isMobile || isTablet || isIPad13}>
          <div class="pluto_video_download_article_non_desktop">
            <object height="100%" width="100%" data="https://www.youtube.com/embed/eOtRh3uceCI"/>
          </div>         
          <div class="global_download_links_notation_for_non_desktop">
          <p>Visit this site on desktop to download Pluto</p>
          </div>
        </CustomView>

      </section>
    )
}

export default pluto;
