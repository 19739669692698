import React, {useCallback} from 'react'
import {CustomView, isBrowser, BrowserView} from 'react-device-detect'
import Classes from './MassMailer.css'

const GMAIL_SECURITY_INFO_LINK='https://myaccount.google.com/lesssecureapps';
const MASSMAILER_BLOGPOST_LINK ='https://coffeefromme.blogspot.com/2017/01/free-mass-email.html';
const DOWNLOAD_HOME = "download/massmailer/2.0";

const massMailer = () =>{

    return (
        <section class="product_section mass_mailer_product_section">
        <h2>RATIONALMINDS MASSMAILER</h2>
        <div>
          <p class="product_text">Send bulk emails addressed individually for free. Emails are universally used, and for special occasions or targeted marketing, sending personalized emails can create a significant impact.</p> 
          <p class="product_text">While there are numerous paid services available, very few offer this functionality entirely for free.</p> 
          <p class="product_text">RationalMassMailer is an incredibly user-friendly tool that allows you to send impactful emails. This service is compatible with Gmail and Yahoo accounts. To enable access for third-party applications, you may need to adjust your Gmail security settings. Click <a href={GMAIL_SECURITY_INFO_LINK}>here</a> for instructions on configuring your Gmail access security for unregistered third-party apps. Gmail and Yahoo both impose daily limits, approximately **500 emails for Gmail and Yahoo users**, so RationalMassMailer allows you to set your preferred sending rate to stay within these limits.</p>
          <p class="product_text">For more details visit the RationalMassMailer on <a href={MASSMAILER_BLOGPOST_LINK}>this blogpost</a>.</p>
        </div>
        <BrowserView>
        <div class="mass_mailer_download_options"><p>Download Rational Mass E-mail Sender</p></div>
        <div class="mass_mailer_download_options">
          <a href={DOWNLOAD_HOME+"/RationalMassMailer.exe"}><div class = "mass_mailer_download_windows"/></a>
          <a href={DOWNLOAD_HOME+"/RationalMassMailer.pkg"}><div class = "mass_mailer_download_mac" /></a>
          <a href={DOWNLOAD_HOME+ "/RationalMassMailer.jar"}><div class = "mass_mailer_download_linux" /></a>
        </div>
        </BrowserView>
        <CustomView class="global_download_links_notation_for_non_desktop" condition={isBrowser === false}>
          <p>Visit this site on desktop to download MassMailer</p>
        </CustomView>
      </section>
    )
}

export default massMailer;