import React from 'react';
import Pluto from './components/Pluto/Pluto'
import MassMailer from './components/MassMailer/MassMailer'
import DateParser from './components/DateParser/DateParser'
import Calculators from './components/Calculators/Calculators'
import './App.css';
import banner from './images/banner.png'
import logo from './images/logo_50.png'

function App() {
  return (
    <div class="container"> 
      <header> <img src={logo} alt="" width="50" height="50" class="logoimg"/>
        <h4 class="logo">RATIONALMINDS</h4>
        <nav>
          <ul>
            <li><a href="#hero">HOME</a></li>
            <li><a href="#products">PRODUCTS</a></li>
            <li><a href="#contact">CONTACT</a></li>
          </ul>
        </nav>
      </header>

    <section class="hero" id="hero"> <img src={banner}  alt="" style={{'max-width':'100%','max-height':'100%'}}/> </section>

    <section class="about" id="about">
      <h2 class="hidden">About</h2>
      <p class="text_column"> At Rational Minds, we are dedicated to crafting streamlined, intuitive tools that elevate daily operations. Our philosophy centers around simplicity and impact—delivering compact, intelligent solutions that meet needs efficiently without the burden of unnecessary complexity. We prioritize functionality over flash, developing tools that focus precisely on the task at hand. These tools are not only easy to deploy and maintain but are also designed to run efficiently without the need for specialized environments or high-end hardware. Our commitment to green IT reflects our belief in maximizing current resources sustainably before pursuing additional ones. </p> 
      <p class="text_column"> Our solutions are engineered for minimal maintenance, requiring little to no configuration and allowing organizations to operate seamlessly with reduced technical overhead. Designed to empower rather than encumber, our tools support operational ease and ensure that technology remains an enabler—not an obstacle. Our guiding principle is simple: focus on the mission, not the mechanism. Just as a dependable tool should be ready to serve without thought, our products are built to perform reliably whenever they are needed. </p> 
      <p class="text_column"> We aim to liberate human resources from monotonous, repetitive tasks, enabling talent to drive innovation and transformation. By helping organizations redirect their energy towards meaningful progress, we empower them to reach the strategic goals they’ve set. Our tools are freely available for extended use, allowing users to download, install, and integrate them as best suits their needs, fostering long-term value without immediate costs. To ensure compliance and continued benefit, we invite you to review the specific license terms accompanying each of our tools. </p>
    </section>


  
    <section class="banner">
      <h2 class="parallax">RATIONALMINDS SOFTWARES</h2>
      <p class="parallax_description">Optimizing IT Operations</p>
    </section>

    <Pluto/>

    <MassMailer/>
    
    <DateParser/>

    <Calculators/>

  <footer> 
   <section class="product_section">
   <h2 id="contact">CONTACT US</h2>
   <div class="footer_column">
    <div>Address: 12655 morningpark cir, Alpharetta, GA, USA 30004</div>
    <div>Email: admin@rationalminds.net</div>
    <div>Phone : +16787758180</div>
    </div>
   </section>
  </footer>
  <div class="copyright">&copy;2020 - <strong>RATIONAMINDS</strong></div>
</div>
  );
}

export default App;
