import React from 'react';

const dateParser = () =>{
    return(
        <section class="product_section">
        <h2>DATE PARSER</h2>
        <div>
        <p class="product_text">
            The Date Parser library is a complimentary tool designed to parse dates and times directly from text inputs. It efficiently recognizes and converts these into valid Java date objects, supporting any specified Java date format through a streamlined linear identification algorithm. Additionally, it provides details on the pattern, length, and position of date elements within the text, making it ideal for applications such as log parsing and analyzing unstructured files to extract meaningful date-time data.
        </p>

        <p class="product_text">
            This library also includes an advanced regular expression generator, capable of creating optimized expressions to filter text or log files based on specific date-time ranges down to the second, in any given format.
        </p>

        <p class="product_text">
            Pluto leverages these robust features to enhance log analysis.
        </p>


          <p><a href="http://coffeefromme.blogspot.com/2015/10/utility-to-extract-date-from-text-with.html" title="blogger" target="_blank" class="external_links">Follow this link at blogger </a></p>
          <p><a href="https://github.com/vbhavsingh/DateParser" title="github" target="_blank" class="external_links">Follow and contribute at GitHub</a></p>
        </div>
      </section>
    )
} 

export default dateParser;